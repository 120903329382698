<template>
	<v-form
	v-if="core"
	ref="form"
	@submit="e => e.preventDefault()"
	class="tw-grid tw-grid-cols-12 tw-w-full tw-gap-x-[10px] tw-gap-y-[10px] tw-overflow-hidden tw-transition-all tw-pt-[5px]"
	:class="{
		'tw-max-h-[46px]': isHidden
	}"
	core-form-1
	>
		<ButtonSlot
		v-if="campaignStatus.step > 2"
		_theme="light-gray"
		class="tw-col-span-12 tw-mb-[10px] tw-h-[36px]"
		@click="isHidden = !isHidden"
		>
			{{ isHidden === true? "voir infos d'implantation" : "cacher infos d'implantation" }}
		</ButtonSlot>

		<h3
		v-if="core.status === 'cancelled'"
		class="tw-col-span-12 tw-flex tw-justify-center tw-mb-[10px]"
		style="color: red"
		>
			Carotte annulée
		</h3>

		<v-select
		:items="displayNamePrefixes"
		item-text="name"
		item-value="id"
		return-object
		v-model="core.displayNamePrefix"
		outlined
		dense
		label="Préfixe (ID campagne)*"
		tabindex="1"
		:disabled="isDisabled() || this.$hasRight('users.isCustomer') || (core.status === 'sent_to_provider' || core.status === 'results_acquired')"
		class="tw-col-span-6"
		validate-on-blur
		:rules="[$rules.required]"
		/>

		<v-text-field
		tabindex="1"
		class="tw-col-span-6"
		label="ID de la carotte (3 chiffres max.)*"
		v-model="core.displayName"
		dense
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		:rules="[$rules.validId, $rules.required, hintId]"
		hide-details
		/>

		<v-text-field
		tabindex="1"
		class="tw-col-span-12"
		label="Identifiant client"
		v-model="core.customerId"
		dense
		outlined
		clearable
		validate-on-blur
		:disabled="isDisabled()"
		/>

		<v-combobox
		class="tw-col-span-12"
		tabindex="1"
		:rules="[$rules.requiredRoad]"
		:items="roads"
		item-text="name"
		item-value="id"
		return-object
		v-model="core.road"
		outlined
		clearable
		dense
		validate-on-blur
		label="Nom de la voie*"
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		/>

		<v-combobox
		class="tw-col-span-12"
		tabindex="1"
		:items="cities"
		item-text="name"
		item-value="id"
		return-object
		v-model="core.city"
		outlined
		clearable
		dense
		validate-on-blur
		label="Nom de la commune"
		:disabled="isDisabled()"
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.direction"
		:items="roadWayField"
		:item-text="item => item.name"
		:item-value="item => item.id"
		label="Sens de la voie"
		data-vv-name="select"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		clearable
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.lane"
		:items="formVMField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Localisation/Voie"
		data-vv-name="select"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		clearable
		/>

		<v-text-field
		dense
		tabindex="1"
		class="tw-col-span-5"
		v-model="core.landmark"
		label="PR"
		outlined
		clearable
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		:rules="[
			$rules.onlyNumbers,
			$rules.FourMax,
			$rules.landmarkMustBeFilled(
				core.landmark,
				core.abscissa
			)
		]"
		validate-on-blur
		/>

		<span class="tw-col-span-2 tw-text-center tw-text-[25px]">
			+
		</span>

		<v-text-field
		tabindex="1"
		dense
		class="tw-col-span-5"
		v-model="core.abscissa"
		label="ABS"
		outlined
		clearable
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		:rules="[
			$rules.onlyNumbers,
			$rules.FourMax,
			$rules.abscissaMustBeFilled(
				core.landmark,
				core.abscissa
			)
		]"
		validate-on-blur
		/>

		<v-select
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.lateralPosition"
		:items="lateralPosField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Latéralisation*"
		data-vv-name="select"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		:rules="[$rules.required]"
		validate-on-blur
		clearable
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-10"
		v-model="core.structureType"
		:items="stTypeField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Type de Structure"
		data-vv-name="select"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		clearable
		/>

		<ButtonSlot
		_icon="mdi-help-circle-outline"
		@click="emitOpenClose()"
		_theme="light-gray"
		class="tw-ml-[10px] tw-col-span-2"
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.isFullStructure"
		:items="stCompleteField"
		:item-text="item => item.name"
		:item-value="item => item.bool"
		label="Structure Complète*"
		:rules="[$rules.requiredBoolean]"
		data-vv-name="select"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		clearable
		/>

		<v-select
		tabindex="1"
		dense
		class="tw-col-span-12"
		:items="formDiameter"
		v-model="core.diameter"
		:rules="[$rules.requiredDiameter]"
		label="Diamètre*"
		outlined
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		clearable
		/>

		<v-select
		v-if="campaign.coringType === 'CS'"
		tabindex="1"
		dense
		class="tw-col-span-12"
		:items="formDamages"
		v-model="core.damage"
		:item-text="item => $t(item.name)"
		item-value="name"
		label="Dégradation présente"
		outlined
		clearable
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		/>

		<v-textarea
		v-if="core.damage === 'other'"
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.otherDamage"
		label="Dégradation autre*"
		:rules="[$rules.required]"
		placeholder="Dégradation autre"
		rows="1"
		outlined
		auto-grow
		clearable
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		/>

		<v-textarea
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.note"
		label="Note Supplémentaire"
		outlined
		rows="1"
		:disabled="isDisabled() || this.$hasRight('users.isCustomer')"
		validate-on-blur
		auto-grow
		clearable
		/>

		<v-textarea
		v-if="this.$hasRight('users.isCustomer')"
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.customerNote"
		label="Note validation du prélèvement"
		outlined
		rows="1"
		validate-on-blur
		auto-grow
		clearable
		/>

		<ButtonSlot
		v-if="images.length === 0 && !$hasRight('users.isCustomer')"
		v-show="!isDisabled()"
		class="tw-col-span-12"
		@selectFile="(event) => addImage(event, 'technicianHelp')"
		_select-file-accept="image/png, image/jpeg"
		_theme="light-gray"
		>
			ajouter une image
		</ButtonSlot>

		<ButtonSlot
		v-if="images.length === 1 || images.length === 0 && $hasRight('users.isCustomer')"
		v-show="!isDisabled()"
		class="tw-col-span-12"
		@selectFile="(event) => {
			if(this.$hasRight('users.isCustomer'))
			{
				addImage(event, 'technicianHelpSpeCustomer')
			}else
			{
				let isTechnicianHelpPicture = this.images.find(image => image.type === 'technicianHelp')
				isTechnicianHelpPicture === undefined ? 
					addImage(event, 'technicianHelp') : 
					addImage(event, 'technicianHelpSpeCustomer')
			} 
		}"
		_select-file-accept="image/png, image/jpeg"
		_theme="light-gray"
		>
			{{ this.$hasRight('users.isCustomer') ? 'Ajouter une image': 'Ajouter une seconde image' }}
		</ButtonSlot>
        
		<div
		v-if="images.length !== 0"
		class="tw-col-span-12"
		>
			<div 
			class="tw-w-full tw-flex tw-justify-center tw-flex-col"
			>
				<div
				v-for="image in images"
				:key="image.id"
				class="tw-relative tw-m-2"
				>
					<img
					class="tw-w-full"
					:src="image.url"
					/>
					<ButtonSlot
					_icon="mdi-delete"
					class="tw-top-[10px] tw-right-[10px] tw-absolute"
					@click="removeImage(image.type)"
					_iconSize="20px"
					v-if="$hasRight('campaigns.editCampaignBtn') || (image.type === 'technicianHelpSpeCustomer' && $hasRight('users.isCustomer'))"
					/>
				</div>
			</div>
		</div>

		<ButtonSlot
		v-if="(core.status === 'empty' || core.status === 'waiting') && !this.$hasRight('users.isCustomer')"
		class="tw-col-span-12"
		_theme="light-gray"
		@click="START_MOVING_CORE(core.id); moveMarker()"
		>
			Modifier l'emplacement sur la carte
		</ButtonSlot>

		<i
		v-if="(core.status === 'empty' || core.status === 'waiting') && !this.$hasRight('users.isCustomer')"
		style="color: grey; font-size: 12x;"
		class="tw-col-span-12 tw-text-center"
		>
			Vous pouvez aussi déplacer la carotte sur la carte, à partir d’un double clique sur celle-ci.
			Nous vous conseillons également de placer vos carottes sur la carte avec le zoom maximum, améliorant ainsi la précision.
			Plus le zoom est lointain, moins le placement sera précis.
		</i>
	</v-form>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import core from "../../utils/core";

export default {
	name: "CoringCoreForm1",
	components: {

	},
	data(){
		return {
			isHidden: false,

			core: false,
			images: [],
			inputNumber: "",
			isOpenStructuralType: false,

			roadWayField: [
				{id: 1, name: "Sens 1"},
				{id: 2, name: "Sens 2"},
				{id: 3, name: "Autre"}
			],
			formVMField: [
				{id: 1, name: "BDG"},
				{id: 2, name: "VR"},
				{id: 3, name: "VM"},
				{id: 4, name: "VL"},
				{id: 5, name: "VSVL"},
				{id: 6, name: "BAU"},
				{id: 7, name: "VD"},
				{id: 8, name: "VG"},
				{id: 9, name: "Trottoir"},
				{id: 10, name: "Parking"},
				{id: 11, name: "Voie bus"},
				{id: 12, name: "Piste cyclable"},
				{id: 13, name: "Plateforme"},
				{id: 14, name: "Giratoire"},
				{id: 15, name: "V1"},
				{id: 16, name: "V2"},
				{id: 17, name: "V3"},
				{id: 18, name: "V4"},
				{id: 19, name: "V5"},
				{id: 20, name: "V6"},
				{id: 21, name: "V7"},
				{id: 22, name: "V8"},
				{id: 23, name: "V9"},
				{id: 24, name: "V10"},
				{id: 25, name: "V11"},
				{id: 26, name: "V12"},
				{id: 27, name: "V13"},
				{id: 28, name: "V14"},
				{id: 29, name: "V15"},
				{id: 30, name: "V16"},
				{id: 31, name: "V17"},
				{id: 32, name: "V18"},
				{id: 33, name: "V19"},
				{id: 34, name: "V20"},
				{id: 35, name: "EE"},
				{id: 36, name: "EI"},
				{id: 37, name: "Bretelle"},
				{id: 50, name: "Bretelle 1"},
				{id: 51, name: "Bretelle 2"},
				{id: 52, name: "Bretelle 3"},
				{id: 53, name: "Bretelle 4"},
				{id: 54, name: "Bretelle 5"},
				{id: 55, name: "Bretelle 6"},
				{id: 56, name: "Bretelle 7"},
				{id: 57, name: "Bretelle 8"},
				{id: 58, name: "OA - BAU"},
				{id: 59, name: "OA - VL"},
				{id: 60, name: "OA - VR"},
				{id: 61, name: "VA"},
				{id: 62, name: "VM1"},
				{id: 63, name: "VM2"},
				{id: 64, name: "VM3"},
				{id: 65, name: "VM4"},
				{id: 66, name: "VM5"},
				{id: 38, name: "TPC"},
				{id: 39, name: "ITPC"},
				{id: 40, name: "Refuge"},
				{id: 41, name: "Accès de service"},
				{id: 42, name: "Ouvrage d'art"},
				{id: 43, name: "Parking PL"},
				{id: 44, name: "Parking VL"},
				{id: 45, name: "Voierie légère"},
				{id: 46, name: "Voierie lourde"},
				{id: 47, name: "Parking VL REM"},
				{id: 48, name: "Chemin piéton"},
				{id: 49, name: "Exploitation"},
				{id: null, name: "Autre"}
			],
			lateralPosField: [
				{id: 1, name: "BDR G"},
				{id: 2, name: "Axe"},
				{id: 3, name: "BDR D"},
				{id: 4, name: "Autre"}
			],
			stTypeField: [
				{id: 1, name: "Souple"},
				{id: 2, name: "Bitumineuse épaisse"},
				{id: 3, name: "Inverse et semi-rigide (GTLH)"},
				{id: 4, name: "Rigide (béton)"},
				{id: 5, name: "Mixte"},
				{id: 6, name: "Inverse"},
				{id: 7, name: "Semi-rigide"},
			],
			stCompleteField: [
				{bool: true, name: "Oui"},
				{bool: false, name: "Non"}
			],
			formDiameter: [
				50, 80, 100, 150, 200, 250
			],
			formDamages: [
				{id: 1, name: "degradation"},
				{id: 2, name: "tearing off"},
				{id: 3, name: "various cracks"},
				{id: 4, name: "longitudinal joint"},
				{id: 5, name: "crazing"},
				{id: 6, name: "longitudinal crack"},
				{id: 7, name: "transverse crack"},
				{id: 8, name: "pothole"},
				{id: 9, name: "cracks in slabs"},
				{id: 10, name: "sagging"},
				{id: 11, name: "glaze - penetrant"},
				{id: 12, name: "repair"},
				{id: 13, name: "corner break"},
				{id: 14, name: "hollow rut"},
				{id: 15, name: "rut edge"},
				{id: 16, name: "healthy area"},
				{id: 17, name: "other"}
			]
		};
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "campaign", "displayNamePrefixes", "roads", "campaignStatus", "cores", "map", "cities"
		]),

		rulesId(){
			return this.core.displayName < 999 ? [] : [this.$rules.required];
		},
		hintId(){
			for(const core of this.cores){
				if(
					core.id !== this.core.id &&
                    core.displayNamePrefix?.name + core.displayName === this.core.displayNamePrefix?.name + this.core.displayName
				) return "Cette ID est déjà utilisé.";
			}
			return true;
		}
	},
	watch: {
		editingCore(){
			if(this.editingCore !== false) this.init();
		}
	},
	methods: {
		...mapActions("coring", [
			"updateRoads", "refreshCore", "updateDNP", "updateCore", "updateCities"
		]),
		...mapMutations("coring", ["START_MOVING_CORE", "STOP_MOVING_CORE"]),

		emitOpenClose(){
			this.isOpenStructuralType = !this.isOpenStructuralType;
			this.isOpenStructuralType ? this.$emit("openStructuralType") : this.$emit("closeStructuralType");
		},
		moveMarker(){
			this.map.on("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = this.moveMarkerStop;
			this.map.getCanvas().style.cursor = "crosshair";
		},

		moveMarkerEventClick(e){
			this.moveMarkerStop();
			this.updateCore({...this.core, lat: e.lngLat.lat, lng: e.lngLat.lng});
		},

		moveMarkerStop(){
			this.map.off("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = () => {};
			this.map.getCanvas().style.cursor = "";
			this.STOP_MOVING_CORE();
		},

		async submit(){
			if(this.$refs.form.validate()){
				await this.checkRoad();
				await this.checkCity();
				await this.checkDNP();
				return this.core;
			}
			else return false;
		},

		async checkRoad(){
			if(this.core.road.id === undefined){
				this.core.road = await this.$api.roads.create({
					name: this.core.road,
					category: "a_road",
					workspaceId: this.campaign.workspace.id,
				});
				await this.updateRoads();
			}
		},

		async checkCity(){
			if(this.core.city !== null && this.core.city !== undefined){
				if(this.core.city.id === undefined){
					this.core.city = await this.$api.cities.create({
						name: this.core.city,
						workspaceId: this.campaign.workspace.id,
					});
					await this.updateCities();
				}
			}
		},

		async checkDNP(){
			if(this.core.displayNamePrefix !== null && this.core.displayNamePrefix.id === undefined){
				await this.$api.displayNamePrefixes.create({
					name: this.core.displayNamePrefix,
					coringCampaignId: this.campaign.id,
				});
				await this.updateDNP();
			}
		},

		async addImage({target: {files: [file]}}, type){
			let newCorePic = await this.$api.corePictures.upload(file, {coreId: this.core.id, type: type});
			this.core.corePictures.push(newCorePic);
			this.images.push({id: newCorePic.id, url: URL.createObjectURL(file), type: type});
			//this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async removeImage(type){
			await this.$api.corePictures.remove(
				this.core.corePictures.find(value => value.type === type).id
			);
			this.core.corePictures = [];
			let index = this.images.findIndex(e => e.type === type);
			this.images.splice(index, 1);
			//this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async init(){
			if(this.campaignStatus.step > 2) this.isHidden = true;

			this.core = {...this.editingCore};
			let pictures;
			this.core.corePictures !== undefined ?
				pictures = this.core.corePictures.filter(value => value.type === "technicianHelp" || value.type === "technicianHelpSpeCustomer") : null;
			let url;
			pictures.forEach(async picture => {
				url = URL.createObjectURL(
					await this.$api.documents.download(
						this.campaign.workspace.site.organization.id + 
                        "/" + 
                        picture.path
					)
				);
				this.images.push({id: picture.id, url: url, type: picture.type});
			});

			if(this.core.status === "cancelled") this.isHidden = false;
		},
		sortFields(){
			this.formVMField.sort(function(a, b){
				if(a.name === "Autre") return;
				return a.name.localeCompare(b.name);
			});
			this.lateralPosField.sort(function(a, b){
				if(a.name === "Autre") return;
				return a.name.localeCompare(b.name);
			});
			this.stTypeField.sort(function(a, b){
				if(a.name === "Autre") return;
				return a.name.localeCompare(b.name);
			});
			this.roads.sort(function(a, b){
				if(a.name === "Autre") return;
				return a.name.localeCompare(b.name);
			});
			this.cities.sort(function(a, b){
				if(a.name === "Autre") return;
				return a.name.localeCompare(b.name);
			});
		},
		isDisabled(){
			return !(
				this.campaign.status === "awaiting_validation" ||
				this.campaign.status === "taken" ||
				this.campaign.status === "taking_validated" || 
				(this.campaign.status === "programing_validated" && this.core.status === "sent_to_study_manager")
			);
		}
	},
	created(){
        
	},
	mounted(){
		this.init();
		this.sortFields();
		if(this.campaign.status === "awaiting_validation"){
			if(this.core.displayNamePrefix === null){
				this.core.displayNamePrefix = this.displayNamePrefixes.find(e => e.name.slice(0, -1) === this.campaign.business);
			}
			if(this.core.displayName === "000"){
				let number = this.core.number.toString();
				this.core.displayName = number.padStart(3, "0");
			}
		}
	}
    
};
</script>

<style lang="scss">
form[core-form-1]{

    .v-messages__wrapper {
        color: #ec7505;
    }
}
.v-text-field.v-text-field--enclosed .v-text-field__details{
	margin-bottom: 0px !important
}

</style>
